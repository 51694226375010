import $ from 'jquery'
import { Foundation } from '../../../node_modules/foundation-sites/dist/js/foundation.min'

// Uncomment a module to load it
// import { Abide } from '../../../node_modules/foundation-sites/js/foundation.abide.js'
// import { Accordion } from '../../../node_modules/foundation-sites/js/foundation.accordion'
// import { AccordionMenu } from '../../../node_modules/foundation-sites/js/foundation.accordionMenu'
// import { Drilldown } from '../../../node_modules/foundation-sites/js/foundation.drilldown'
// import { Dropdown } from '../../../node_modules/foundation-sites/js/foundation.dropdown'
// import { DropdownMenu } from '../../../node_modules/foundation-sites/js/foundation.dropdownMenu'
// import { Equalizer } from '../../../node_modules/foundation-sites/js/foundation.equalizer'
// import { Interchange } from '../../../node_modules/foundation-sites/js/foundation.interchange'
// import { Magellan } from '../../../node_modules/foundation-sites/js/foundation.magellan'
// import { OffCanvas } from '../../../node_modules/foundation-sites/js/foundation.offcanvas'
// import { Orbit } from '../../../node_modules/foundation-sites/js/foundation.orbit'
// import { ResponsiveMenu } from '../../../node_modules/foundation-sites/js/foundation.responsiveMenu'
// import { ResponsiveToggle } from '../../../node_modules/foundation-sites/js/foundation.responsiveToggle'
// import { Reveal } from '../../../node_modules/foundation-sites/js/foundation.reveal'
// import { Slider } from '../../../node_modules/foundation-sites/js/foundation.slider'
// import { SmoothScroll } from '../../../node_modules/foundation-sites/js/foundation.smoothScroll'
// import { Sticky } from '../../../node_modules/foundation-sites/js/foundation.sticky'
// import { Tabs } from '../../../node_modules/foundation-sites/js/foundation.tabs'
// import { Toggler } from '../../../node_modules/foundation-sites/js/foundation.toggler'
// import { Tooltip } from '../../../node_modules/foundation-sites/js/foundation.tooltip'
// import { ResponsiveAccordionTabs } from '../../../node_modules/foundation-sites/js/foundation.responsiveAccordionTabs'

/**
 * Notes:
 *
 * If you need a components for a single page then create another JS file,
 * inlude it within the scripts-list.js file and import the needed assets.
 * Then use the wp_enqueue_script to inlude it on the page you need through
 * the functions.php file.
 *
 * Also, consider code splitting for keeping the initial size of the JS
 * loaded on the page down:
 *
 * @link https://webpack.github.io/docs/code-splitting.html
 */

Foundation.addToJquery($)

// Foundation.plugin(Tabs, 'Tabs')

$(document).foundation()
